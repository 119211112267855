@mixin font-black {
	font-weight: 900;
}

@mixin font-bold {
	font-weight: 700;
}

@mixin font-semibold {
	font-weight: 600;
}

@mixin font-extrabold {
	font-weight: 800;
}

@mixin font-medium {
	font-weight: 500;
}

@mixin font-regular {
	font-weight: 400;
}

@mixin invert-anchor-hover {
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}