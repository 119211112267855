$enable-responsive-font-sizes: true;
$navbar-dark-color: #fff;
$navbar-dark-hover-color: rgba(#fff, .5);
$logo-max-height: 40px;
$primary: #982023;
$btn-font-weight: 600;
$body-color: #333333;
// $border-color: #ebebeb;
$light: #ebebeb;
$spacer: 1rem;
$spacers: (
	5: ($spacer * 2),
	6: ($spacer * 3)
);