@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";

.userButton {
	@include button-variant(white, white);
	color: #333;
}

.searchIcon {
	background-color: black;
	color: white;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 0;
	padding: 0 !important;
}

.searchBar {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}