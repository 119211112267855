@import "_variables";

.cartItem_imageContainer {
	width: 100px;
	height: 100px;
	border-radius: 1rem;
	border: 1px solid #ebebeb;

	&:hover {
		border-color: $primary;
	}

	img {
		border-radius: 1rem;
		max-width: 100%;
		max-height: 100%;
	}
}