$iconSize: 40px;

.cartIcon {
	position: relative;
	display: inline-block;
	width: $iconSize;
	text-align: center;
}

.cartIcon_count {
	text-align: center;
	border-radius: 50%;
	background-color: black;
	width: 20px;
	height: 20px;
	font-size: 0.75rem;
	position: absolute;
	top: -8px;
	left: 0;
	right: 0;
	margin-left: 13px;
}

.card_container {
	z-index: 4;
}

.card {
	width: 400px;
}

.card_header {
	text-transform: uppercase;
	color: white;
	background-color: black !important;
	border-color: black;
	text-align: center;
}

.card_body {
	max-height: 500px;
	overflow-y: auto;
	.btn {
		font-size: 0.75rem;
	}
}

.cartItem_remove {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 3;
}

.cartItemRight {
	font-size: 0.75rem;
}