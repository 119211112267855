@import "_variables";

.card_container {
	z-index: 4;
}

.icon {
	width: 80px;
	height: 80px;
	border-radius: 1rem;
	margin-right: 0.75rem;
}

.card {
	width: 400px;
}

.card_header {
	text-transform: uppercase;
	color: white;
	background-color: black !important;
	border-color: black;
	text-align: center;
}

.notificationItem {
	cursor: pointer;
	&:hover {
		background-color: $light;
	}
}