@import "_variables";
@import "_mixins";

.quantityInput {
	width: 60px !important;
	display: inline-block !important;
	text-align: center;
	margin-left: 0.5rem;
	margin-right: 0.5rem;

	-moz-appearance:textfield; /* Firefox */

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		/* display: none; <- Crashes Chrome on hover */
		-webkit-appearance: none;
		margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}
}

.quantityDec, .quantityInc {
	@include font-bold;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	width: 24px;
	height: 24px;
	font-size: 18px;
	text-align: center;
	padding: 0;
}

.quantityDec {
	background-color: #ebebeb; 
	color: #333;

	&:disabled {
		opacity: .65;
	}
}

.quantityInc {
	background-color: $primary;
	color: white;

	&:disabled {
		opacity: .65;
	}
}

.button_container {
	display: inline-block;
	vertical-align: middle;
}