@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';
@import "_mixins";
@import "_variables";

@include media-breakpoint-up(lg) { 
    .footerImage {
        background-image: url("/images/footer-left.svg"), url("/images/footer-right.svg");
        background-repeat: no-repeat;
        background-size: 100px 100px, contain;
        background-position: bottom -0.25rem left, bottom -1.75rem right;
    }
    
    .supportEmailText {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        font-size: 0.75rem;
    }	
}

.footer {
    @include font-medium;
    font-size: 0.75rem;
    background-color: black;
    margin-top: auto;

    .navigations {
        padding-top: 2rem;
        padding-bottom: 4rem;
    }

    .navTitle {
        @include font-extrabold;
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    .illustration {
        background-color: $primary;
        color: white;
        font-size: .8rem;

        img {
            margin-top: -4rem;
            width: 100%;
            display: inline-block;
        }
    }
}