@import "_variables";
@import "_mixins";

.dropdownContainer {
	display: block;
    width: 200px;
    position: absolute !important;
    top: 0px;
    right: auto;
    bottom: auto;
    left: 0px;
    z-index: 1000;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.dropdownBody {
	overflow-y: auto;
	max-height: 400px
}

.navLink {
	@include font-semibold;
	display: block;
	color: $body-color !important;
	margin-bottom: 0.5rem;

	&:hover {
		@include font-extrabold;

		color: $primary !important;
		text-decoration: none;
	}
}